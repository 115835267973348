import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import Page from "../common/page/Page.js";
import SignInForm from "./SignInForm.js";
import "./SignInPage.scss";

export default function SignInPage() {
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "SignInPage");

  return (
    <Page className="SignInPage">
      <Page.Title
        text={t("authentication-ui:sign_in_page_title")}
        loading={tLoading}
      />
      <Page.Content>
        <div className={"SignInPage"}>
          <Row>
            <Col>
              <SignInBlock />
            </Col>
          </Row>
        </div>
      </Page.Content>
    </Page>
  );
}

function SignInBlock() {
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  useShowLoader(tLoading);

  return (
    <div className="SignInBlock">
      <Row>
        <Col>
          <h1>{t("authentication-ui:sign_in_title")}</h1>
        </Col>
      </Row>
      <Row>
        <Col className="sign-in-form-col">
          <div className="sign-in-form-div">
            <SignInForm />
          </div>
        </Col>
      </Row>
    </div>
  );
}
