import { React } from "react";
import Form from "react-bootstrap/Form";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import FormGroup from "react-bootstrap/FormGroup";
import StringUtils from "../../utils/StringUtils.js";
import ActionButton from "../common/widgets/ActionButton.js";
import FormText from "react-bootstrap/FormText";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import useAuthUser from "../authentication-no-ui/useAuthUser.js";
import useSignUp from "../authentication-no-ui/useSignUp.js";
import * as ROUTES from "../../constants/routes.js";
import { Navigate, useLocation } from "react-router-dom";
import useReferrer from "../infra-no-ui/navigation/useReferrer.js";
//import SignInWithAuthProvider from "./SignInWithAuthProvider.js";
import ActionLink from "../common/widgets/ActionLink.js";
//import Separator from "../common/widgets/Separator.js";
import ValidatedInputText from "../common/widgets/ValidatedInputText.js";
import { useValidatedInputForm } from "../common/widgets/useValidatedInputForm.js";
import ValidatedInputEmail from "../common/widgets/ValidatedInputEmail.js";
import { MIN_LENGTH_PASSWORD } from "../../constants/ui.js";
import Terms from "../footer/Terms.js";
import CloseIcon from "@mui/icons-material//Close";
import useGenericModal from "../common/modals/useGenericModal.js";
import RouterUtils from "../../utils/RouterUtils.js";
import "./SignUpForm.scss";

export default function SignUpForm() {
  const { signUp, loading, error, ready: signUpReady } = useSignUp();
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  const authUser = useAuthUser();
  const { referrer } = useReferrer(ROUTES.HOME);
  const location = useLocation();
  const {redirectTo} = useReferrer();

  useShowLoader(tLoading || !signUpReady, "SignUpForm");

  // If we tried to access a private page without being authenticated, signing up will redirect
  // to referrer. Keep the referrer if user has an account and does not need to sign up, just sign in
  const onClickSignIn = (event) => {
    event.preventDefault();
    const signInLocationWithReferrer = {
      pathname: ROUTES.SIGN_IN,
      state: location.state,
    };

    redirectTo(signInLocationWithReferrer);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const fields = getValues();
    signUp(fields.email.trim(), fields.password);
  };

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  // Modal to show Terms details form
  const {
    GenericModal: termsModal,
    show: showTermsModal,
    hide: hideTermsModal,
  } = useGenericModal("termsModal");

  const onClickTerms = () => {
    showTermsModal(getTerms());
  };

  const getTerms = () => {
    return (
      <div className="terms-modal">
        <Row className="close-icon">
          <Col>
            <CloseIcon onClick={hideTermsModal} className="close-icon" />
          </Col>
        </Row>
        <Row>
          <Col>
            <Row>
              <Col>
                <h1>{t("authentication-ui:terms_and_conditions")}</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <Terms onClose={hideTermsModal} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col className="action-close">
            <ActionButton onClick={hideTermsModal}>
              {t("authentication-ui:close_button")}
            </ActionButton>
          </Col>
        </Row>
      </div>
    );
  };

  function onClickRecruiterSignUp(event) {
    event.preventDefault();
    RouterUtils.openExternalUrl(ROUTES.RECRUITER_SIGN_UP);
  }

  // RENDER

  // If already logged, or when rendering after sign up, do not display this page, redirect to referrer or HOME
  if (authUser.authenticated) {
    return <Navigate replace to={referrer} />;
  }

  function renderForm() {
    // For some reason that is internal to Firebase, StyledFirebaseAuth rendered by SignInWithAuthProvider does not re-render
    // when the button labels change, so make sure the form is not displayed until the translation is ready.
    // A more robust solution needs to be found because labels still don't change when language changes
    if (tLoading) return null;

    return (
      <>
        {termsModal}
        <div className={"SignUpForm"}>
          <Row>
            <Col>
              {t("authentication-ui:sign_up_subtitle")}
            </Col>
          </Row>
          {/*
          <div>
            <Row>
              <Col>
                <SignInWithAuthProvider
                  googleButtonLabel={t("authentication-ui:sign_up_with_google")}
                  facebookButtonLabel={t(
                    "authentication-ui:sign_up_with_facebook"
                  )}
                />
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="separator-col">
              <Separator>
                {t("authentication-ui:sign_up_with_email_msg")}
              </Separator>
            </Col>
          </Row>
          */}
          <Row>
            <Col className="input-col-container">
              <Form onSubmit={onSubmit} className="form-with-rows">
                <FormGroup controlId="email">
                  <ValidatedInputEmail
                    required={true}
                    name="email"
                    control={control}
                    className="FormControl"
                    autoFocus
                    type="email"
                    placeholder={t(
                      "authentication-ui:sign_up_email_placeholder"
                    )}
                    autoComplete="email"
                  />
                </FormGroup>
                <FormGroup controlId="password">
                  <ValidatedInputText
                    minLength={MIN_LENGTH_PASSWORD}
                    control={control}
                    required={true}
                    className="FormControl"
                    name="password"
                    type="password"
                    placeholder={t(
                      "authentication-ui:sign_up_password_placeholder"
                    )}
                    autoComplete="current-password"
                  />
                </FormGroup>
                <FormGroup>
                  <div className="FormRow">
                    <ActionButton
                      loading={loading}
                      type="submit"
                      disabled={!canSubmit()}
                    >
                      {t("authentication-ui:sign_up_sign_up_button")}
                    </ActionButton>
                  </div>
                </FormGroup>
                {!StringUtils.isNullOrEmpty(error) && (
                  <FormText as="div" className="form-error">
                    {error}
                  </FormText>
                )}
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              {t("authentication-ui:terms_and_conditions_agree_msg")}
              <ActionLink onClick={onClickTerms}>
                {t("authentication-ui:terms_and_conditions")}
              </ActionLink>
            </Col>
          </Row>
          <Row>
            <Col>
              {t("authentication-ui:sign_up_sign_in_message")}
              <ActionLink className="sign-in-action" onClick={onClickSignIn}>
                {t("authentication-ui:sign_up_sign_in_link")}
              </ActionLink>
            </Col>
          </Row>
          <Row>
            <Col>
              <ActionLink onClick={(event) => onClickRecruiterSignUp(event)}>
                {t("authentication-ui:sign_in_not_employer")}
              </ActionLink>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  return <div className="Signup">{renderForm()}</div>;
}
